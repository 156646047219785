import Model from 'src/models/Model';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Customer from './Customer';
import Interlocutor from './Interlocutor';
import Sector from './Sector';
import { importType } from 'src/helper/ContactImportType';



export default class CustomImport extends Model {

  public name: string
  public sector: Sector
  public data: JSON
  public type: number

  static modelUrl = "customImport";
  public modelName = "customImport";

  public attributes = new AttributesCollection({
    name: new Attribute("name"),
    sector: new ModelAttribute("sector", Sector,  {submitObject: false}),
    data: new Attribute("data"),
    type: new Attribute("type"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
    if(json.data && typeof json.data != "object"){
      this.data = JSON.parse(json.data)
    }
  }

  public getTypeName(){
    return importType[this.type]
  }

}