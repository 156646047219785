import Sector from '@models/Sector';
import ApiService from '@services/ApiService';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';

export interface IJobSectionProps {
}

export default class JobSection extends React.Component<IJobSectionProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      sector: null
    }
    
  }

  loadSectors = async () => {
    const response = await EntityManager.get(Sector);
    if (response && response.data && Array.isArray(response.data.models)) {
      return response.data.models
    } else {
      console.error('Format de réponse inattendu:', response);
    }
  };
  


  componentDidMount(): void {
    this.load();
  }

  async load() {
    let res = await ApiService.get("dashboard/jobs");
    let sectors = await this.loadSectors()
    console.log(sectors)
    this.setState({data: res.data, sectors: sectors})
   
  }

  renderStatus(status) {
    if (status == "processed") return <div className='bg-success text-white py-2 px-3 rounded-2'>Terminé</div>
    if (status == "pending") return <div className='bg-warning text-white py-2 px-3 rounded-2'>En cours</div>
    if (status == "failed") return <div className='bg-danger text-white py-2 px-3 rounded-2'>En échec</div>
    return <></>
  }

  renderName(name, sectorName) {
    console.log(sectorName)
    if (name == "App\\Jobs\\ImportJob" || name == "App\\Jobs\\InterventionCustomImportJob") return <div className='fw-bold'>Import d'intervention secteur {sectorName}</div>
    if (name == "App\\Jobs\\ContactImportJob" || name == "App\\Jobs\\ContactCustomImportJob") return <div className='fw-bold'>Import de contact secteur {sectorName}</div>
    return <></>
  }

  public render() {
    const {data} = this.state;
    if (!data) return <></>
    return (
      <div>
        <div>Activité des imports</div>
        {data.jobs.map(j => (
          <div className='mb-3'>
            {console.log(j.content.data.sector_id)}
            <div className="row-flex">
              {this.renderName(j.content.name, this.state.sectors.find(sector => sector.id == j.content.data.sector_id)?.name)}
              <div className='ms-auto me-4'>
                {this.renderStatus(j.content.status)}
              </div>
              <div>
                {dayjs(j.created_at).format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
            {j.content.status == "failed" && <div className='py-2 text-danger'>
              {j.content.exception.message}
            </div>}
          </div>
        ))}
        {/* <div>Import terminé</div>
        {data.job.map(j => (
          <div>

          </div>
        ))}
        <div>Import en échec</div>
        {data.job.map(j => (
          <div>

          </div>
        ))} */}
      </div>
    );
  }
}
