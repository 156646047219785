import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import TextIcon from '@components/common/TextIcon';
import ContactGrid from '@components/grid/ContactGrid';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Address from '@models/Address';
import Contact from '@models/Contact';
import Contract from '@models/Contract';
import Counter from '@models/Counter';
import Sector from '@models/Sector';
import ApiService from '@services/ApiService';
import DOMService from '@services/DOMService';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export interface SectorPageProps extends IInjectedShowModelProps<Sector> {
    id
}

export interface SectorPageState {
    notification: string | null;
    isLoading: boolean;
    errorMessage: string | null;
}


class SectorPage extends React.Component<SectorPageProps, SectorPageState> {

    public options = [
        {
            label: "Voir les interlocuteurs",
            icon: "fa-user-headset",
            action: () => sessionBloc.push("/crm/sectors/" + this.props.model.id + "/interlocutors")
        },
        {
            label: "Créer un contact",
            icon: "fa-plus",
            action: () => DOMService.openSidebar(SidebarRoute.ContactForm, {
                onSubmit: () => {
                    GetX.get("ContactGrid")?.loadModels();
                    DOMService.closeSidebar();
                }, newModel: new Contact({
                    sectorId: this.props.model?.id,
                    contract: new Contract({}),
                    counter: new Counter({ connectionAddress: new Address({}) })
                })
            })
        },
        {
            label: "Exporter Exclusions",
            icon: "fa-file-export",
            action: () => this.exportExclusion()
        },
        {
            label: "Exporter Contact",
            icon: "fa-file-export",
            action: () => this.exportContact()
        },
        {
            label: "Importer",
            icon: "fa-file-import",
            action: () => DOMService.openSidebar(SidebarRoute.ImportDialog, {
                parentModel: this.props.model,
                onImportSuccess: () => window.location.reload()
            })
        },
        {
            label: "Statistiques",
            icon: "fa-chart-pie",
            action: () => window.open("/crm/sectors/" + this.props.model.id + "/stats/progression")
        },
        {
            label: "Map",
            icon: "fa-map",
            action: () => sessionBloc.push("/crm/sectors/" + this.props.model.id + "/map")
        },
        {
            label: "Voir les infructueux",
            icon: "fa-file-xmark",
            action: () => sessionBloc.push("/crm/sectors/" + this.props.model.id + "/failed")
        },
        {
            label: "Supprimer quartier",
            icon: "fa-xmark",
            action: () => DOMService.openSidebar(SidebarRoute.SectorDelete, {
                onSubmit: () => {
                    //GetX.get("ContactGrid")?.loadModels();
                    window.location.reload()
                },
                sectorId: this.props.model?.id
            })
        },
    ]

    constructor(props: SectorPageProps) {
        super(props);
        this.state = {
            notification: null,
            isLoading: false,
            errorMessage: null,
        };
    }

    componentDidMount(): void {
        this.loadQuartier()
    }

    showNotification(message: string) {
        this.setState({ notification: message });
        setTimeout(() => {
            this.setState({ notification: null });
        }, 3000);
    }


    async loadQuartier() {
        await OptionService.loadQuartier(this.props.id);
        this.setState({})
    }

    async exportExclusion() {
        let response = await ApiService.post("dashboard/crm/export/exclusion", { sectorId: this.props.model.id });
        this.showNotification("Les fiches vont être envoyées par mail");
    }

    async exportContact() {
        let response = await ApiService.post("dashboard/crm/export/contact", { sectorId: this.props.model.id });
        this.showNotification("Les fiches vont être envoyées par mail");

    }

    openUserToQuartierSidebar = () => {
        DOMService.openSidebar(SidebarRoute.UserToQuartier, { sectorId: this.props.id });
    }

    public render() {
        const { model } = this.props;
        const { isLoading, errorMessage } = this.state;

        return (
            <div className="home">
                <div className="px-0 pt-3 px-md-5">
                    {/* Affichage de la notification */}
                    {this.state.notification && (
                        <div className="alert alert-success" role="alert">
                            {this.state.notification}
                        </div>
                    )}
                    <div className="app-content">
                        <div className="row-flex mb-3">
                            <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
                            <h2 className="mb-0 text-capitalize">
                                <span className="me-3 fw-bold">{model.customer.name}</span>
                                <span>{model.name}</span>
                            </h2>
                            <div className="ms-auto">
                                <Dropdown>
                                    <Dropdown.Toggle as={React.forwardRef(({
                                        children,
                                        onClick
                                    }: any, ref: React.LegacyRef<HTMLButtonElement>) => (
                                        <button ref={ref} onClick={onClick} className="btn btn-info">
                                            Actions
                                        </button>
                                    ))} />

                                    <Dropdown.Menu align="end" className="fade" style={{ marginTop: "1rem" }}>
                                        {this.options.map(opt => (
                                            <Dropdown.Item key={opt.label} onClick={opt.action}>
                                                <TextIcon width={4} leftIcon={"fas " + opt.icon}>
                                                    {opt.label}
                                                </TextIcon>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <button className="btn btn-primary ms-3" onClick={this.openUserToQuartierSidebar}>Assigner Worker à Quartier</button>

                        </div>
    
                        {isLoading && (
                            <div className="alert alert-info">Chargement en cours...</div>
                        )}
    
                        {errorMessage && (
                            <div className="alert alert-danger">{errorMessage}</div>
                        )}
    
                        <AdminModelsList
                            gridClass={ContactGrid}
                            key="ContactGrid"
                            getKey="ContactGrid"
                            options={{
                                modelClass: Contact,
                                loadOnReady: true,
                                paginate: true,
                                parentModel: model,
                                defaultState: { filter: JSON.parse(localStorage.getItem("contactGridFilters") || "{}") }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
    

export default showModel(SectorPage, { modelClass: Sector }) as any
