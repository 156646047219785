import CustomImportShow from '@components/crm/CustomImportShow';
import * as React from 'react';

export interface IContactShowPageProps {
  id
}

export default class ImportShowPage extends React.Component<IContactShowPageProps, any> {

  public render() {
    const {id} = this.props
    
    return (
      <div className="home">
        <div className="px-0 pt-3 px-md-5">
          <div className="app-content">
            <CustomImportShow id={id} />
          </div>
        </div>
      </div>
    );
  }
}
