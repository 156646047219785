import AsyncButton from '@components/button/AsyncButton';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import CustomImport from '@models/CustomImport';
import CustomerSelect from '@components/modelSelect/CustomerSelect';
import SmartSelect from '@components/input/SmartSelect';
import { importType } from 'src/helper/ContactImportType';
import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import Select from 'react-select'
import Option from '@models/Option';
import OptionService from '@services/OptionService';
import jschardet from 'jschardet';

export interface ISectorFormProps extends IInjectedFormModelProps<CustomImport> { }

export interface ISectorFormState {
  forceUpdate: boolean;
}

class CustomImportForm extends React.Component<
  ISectorFormProps & IFormModelProps<CustomImport>,
  ISectorFormState
> {
  constructor(props: ISectorFormProps & IFormModelProps<CustomImport>) {
    super(props);

    this.state = {
      forceUpdate: false,
    };
  }

  public changeType(e) {
    this.props.model.type = e.id;
    this.setState({ forceUpdate: !this.state.forceUpdate });
  }

  public changeSector(e) {
    this.setState({ forceUpdate: !this.state.forceUpdate });
  }

  public render() {
    const { model, submit, submitting, id } = this.props;

    return (
      <SidebarLayout
        title={id ? "Modification d'un import" : "Création d'un import"}
        bottomArea={
          <AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit}>
            {id ? "Modifier l'import" : "Créer l'import"}
          </AsyncButton>
        }
      >
        <div className="fadeInDown">
          <div className="">
            <CustomerSelect
              label="Secteur"
              model={model}
              name="sector"
              placeholder="Choisir un secteur"
              containerClassName="mb-3"
              onChange={(e) => this.changeSector(e)}
            />
          </div>
          <div>
            <SmartSelect
              label={"Type d'import"}
              options={importType.map((item, index) => ({
                label: item,
                id: index,
              }))}
              value={
                model.type != null
                  ? { label: importType[model.type], id: importType }
                  : null
              }
              onChange={(e) => this.changeType(e)}
            />
          </div>

          {model.type === 1 ? (
            // Formulaire spécifique pour le site 1
            <div>
              <br></br>
              <h3>Formulaire</h3>
              <AnotherFormComponent
                model={model}
                onUpdate={(value) => (model.data = value)} // Mettre à jour les données du modèle
              />
            </div>
          ) : (
            // Formulaire générique pour les autres sites
            <div>
              <br />
              <ImportConfigurationPage
                onUpdate={(value) => {
                  model.data = value; // Met à jour directement dans le modèle
                }}
                model={model}
              />
            </div>
          )}
        </div>
      </SidebarLayout>
    );
  }
}

/**
 * -----------------------------------------------------------
 *   ImportConfigurationPage
 *   => Formulaire générique (type ≠ 1) pour le mapping CSV
 * -----------------------------------------------------------
 */
const ImportConfigurationPage = ({ onUpdate, model }) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvColumns, setCsvColumns] = useState<string[]>([]);
  const [uniqueKey, setUniqueKey] = useState('');
  const [columnMapping, setColumnMapping] = useState<Record<string, string>>(model.data ? model.data : {});


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      if (e.target?.result) {
        // e.target.result est une "binary string"
        const binaryString = e.target.result as string;

        // Détecter l'encodage via jschardet
        const detection = jschardet.detect(binaryString);
        let encoding = detection.encoding ? detection.encoding.toLowerCase() : 'utf-8';

        // Petit ajustement si besoin
        if (encoding === 'win1252') {
          encoding = 'windows-1252';
        }

        console.log('Encodage détecté :', encoding, '| Confiance :', detection.confidence);

        // Convertir la "binary string" en texte avec l'encodage détecté
        const textContent = decodeBinaryString(binaryString, encoding);

        // Enlever d'éventuels BOM
        const cleanText = textContent.replace(/\uFEFF/g, '');

        // Parser avec Papa Parse
        Papa.parse(cleanText, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const data = results.data as Record<string, unknown>[];
            if (data && data.length > 0) {
              setCsvColumns(Object.keys(data[0]));
            }
          },
          error: (error) => {
            console.error('Erreur parsing CSV :', error);
          },
        });
      }
    };

    // Lire le fichier en "binary string"
    fileReader.readAsBinaryString(file);
  };

  // Convertir une "binary string" en texte via TextDecoder
  const decodeBinaryString = (binaryString: string, encoding: string) => {
    // On reconstruit un tableau d'octets (Uint8Array) à partir de la binary string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i) & 0xff;
    }

    // On utilise TextDecoder pour convertir ces octets en "vrai" texte
    const decoder = new TextDecoder(encoding);
    return decoder.decode(bytes);
  };


  const handleUniqueKeyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const uniqueKeyValue = event.target.value;
    handleMappingChange("unique", uniqueKeyValue)
    //onUpdate(uniqueKeyValue)
  };

  const handleMappingChange = (dbKey: string, csvCol: string) => {
    // Mise à jour des mappings dans data du modèle
    setColumnMapping((prev) => {
      const updatedMapping = { ...prev, [dbKey]: csvCol };
      onUpdate(updatedMapping)
      return updatedMapping;
    });
  };

  // Simulation : on transforme chaque ligne CSV en objet "Contact" (ou similaire)
  // pour ensuite l'afficher avec ContactInfo, ou l'envoyer au backend.
  const handleSubmit = () => {
    /*const contacts: Contact[] = csvData.map((row) => {
      // On construit la même structure de données que `ContactInfo` attend.
      const c = new Contact();

      // On parcourt chaque champ mappé
      Object.keys(columnMapping).forEach((dbKey) => {
        const csvKey = columnMapping[dbKey];
        const value = row[csvKey] ?? '';

        switch (dbKey) {
          case 'email':
          case 'phone':
          case 'portable':
          case 'reference':
          case 'codeRdv':
            (c as any)[dbKey] = value;
            break;

          // Client (ex: c.customer = { name: ... })
          case 'customerName':
            c.customer = { name: value };
            break;

          // Compteur
          case 'counterReference':
            if (!c.counter) c.counter = {} as any;
            c.counter.reference = value;
            break;
          case 'counterDiameter':
            if (!c.counter) c.counter = {} as any;
            c.counter.diameter = value;
            break;
          case 'counterAccess':
            if (!c.counter) c.counter = {} as any;
            c.counter.accessibility = value;
            break;

          // Contrat
          case 'contractRef':
            if (!c.contract) c.contract = {} as any;
            c.contract.ref = value;
            break;
          case 'contractType':
            if (!c.contract) c.contract = {} as any;
            c.contract.type = value;
            break;
          case 'contractStatus':
            if (!c.contract) c.contract = {} as any;
            c.contract.status = value;
            break;

          default:
            console.log('Champ non géré :', dbKey, '=>', value);
            break;
        }
      });

      return c;
    });

    setImportedContacts(contacts);
    */
  };

  // -- Rendu qui ressemble à ContactInfo, mais avec des <select> pour chaque champ --
  const renderMappingSelection = () => (
    <>
      {/* SECTION INFORMATIONS */}
      <div className='mb-3 bg-light p-3'>
        <div className='fw-bold mb-3'>Informations</div>
        {/* Nom & Prénom */}
        <div className='row'>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Nom</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('name', e.target.value)}
                  value={columnMapping['name'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Prénom</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('firstname', e.target.value)}
                  value={columnMapping['firstname'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Email */}
        <div className='mb-3'>
          <div className='text-s text-secondary'>Email</div>
          <div className="row-flex">
            <select
              onChange={(e) => handleMappingChange('email', e.target.value)}
              value={columnMapping['email'] || ''}
            >
              <option value="">-- Sélectionner une colonne CSV --</option>
              {csvColumns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Téléphone & Portable */}
        <div className='row'>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Téléphone</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('phone', e.target.value)}
                  value={columnMapping['phone'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Portable</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('portable', e.target.value)}
                  value={columnMapping['portable'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Référence */}
        <div className='mb-3'>
          <div className='text-s text-secondary'>Référence</div>
          <div className='row-flex'>
            <select
              onChange={(e) => handleMappingChange('reference', e.target.value)}
              value={columnMapping['reference'] || ''}
            >
              <option value="">-- Sélectionner une colonne CSV --</option>
              {csvColumns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>


        {/* Adresse */}
        <div className='row'>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Numéro de rue</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('addresses.number', e.target.value)}
                  value={columnMapping['addresses.number'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Nom de rue</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('addresses.address', e.target.value)}
                  value={columnMapping['addresses.address'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Complément d'adresse</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('addresses.addressComp', e.target.value)}
                  value={columnMapping['addresses.addressComp'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Code postal</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('addresses.cp', e.target.value)}
                  value={columnMapping['addresses.cp'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Ville</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('addresses.city', e.target.value)}
                  value={columnMapping['addresses.city'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION COMPTEUR */}
      <div className='mb-3 bg-light p-3'>
        <div className='fw-bold mb-3'>Compteur</div>
        <div className='row'>
          {/* Référence compteur */}
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Référence</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('counters.reference', e.target.value)}
                  value={columnMapping['counters.reference'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* Diamètre */}
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Diamètre</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('counters.diameter', e.target.value)}
                  value={columnMapping['counters.diameter'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Accessibilité */}
        <div className='mb-3'>
          <div className='text-s text-secondary'>Accessibilité</div>
          <div className='row-flex'>
            <select
              onChange={(e) => handleMappingChange('counters.access', e.target.value)}
              value={columnMapping['counters.access'] || ''}
            >
              <option value="">-- Sélectionner une colonne CSV --</option>
              {csvColumns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* SECTION CONTRAT */}
      <div className='mb-3 bg-light p-3'>
        <div className='fw-bold mb-3'>Contrat</div>
        <div className='row'>
          {/* Référence */}
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Référence</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('contracts.ref', e.target.value)}
                  value={columnMapping['contracts.ref'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* Type */}
          <div className='col-md-6 col-12'>
            <div className='mb-3'>
              <div className='text-s text-secondary'>Type</div>
              <div className='row-flex'>
                <select
                  onChange={(e) => handleMappingChange('contracts.type', e.target.value)}
                  value={columnMapping['contracts.type'] || ''}
                >
                  <option value="">-- Sélectionner une colonne CSV --</option>
                  {csvColumns.map((col) => (
                    <option key={col} value={col}>
                      {col}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Statut */}
        <div className='mb-3'>
          <div className='text-s text-secondary'>Statut</div>
          <div className='row-flex'>
            <select
              onChange={(e) => handleMappingChange('contracts.status', e.target.value)}
              value={columnMapping['contracts.status'] || ''}
            >
              <option value="">-- Sélectionner une colonne CSV --</option>
              {csvColumns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {/* CHARGEMENT DU FICHIER CSV */}
      <div>
        <label htmlFor="csvFile">Charger un fichier CSV :</label>
        <input
          type="file"
          id="csvFile"
          accept=".csv"
          onChange={handleFileUpload}
        />
      </div>

      {/* UNE FOIS LES COLONNES CHARGÉES, ON AFFICHE NOTRE FORMULAIRE */}
      {(csvColumns.length > 0 && model.type != null && !!model.sector) && (
        <div className="mt-4">
          <h2>Référence de la fiche contact:</h2>
          <select value={columnMapping["unique"]} onChange={handleUniqueKeyChange}>
            <option value="">-- Choisir une clé unique --</option>
            <option key={"contact"} value={"contact"}>
              {"Référence du contact"}
            </option>
            <option key={"compteur"} value={"compteur"}>
              {"Référence du compteur"}
            </option>
            <option key={"contract"} value={"contract"}>
              {"Référence du contract"}
            </option>
          </select>
          <br></br>
          <br></br>
          {/* ICI, ON AFFICHE LE FORMULAIRE DE MAPPING AVEC LE MÊME LAYOUT QUE CONTACTINFO */}
          {renderMappingSelection()}
        </div>
      )}

      {/* UNE FOIS LES CONTACTS IMPORTÉS, ON LES AFFICHE COMME CONTACTINFO */}
    </div>
  );
};

/**
 * -----------------------------------------------------------
 *    AnotherFormComponent
 *    => Formulaire spécifique (type = 1) pour le mapping
 * -----------------------------------------------------------
 */
interface AnotherFormComponentProps {
  model: any; // Le modèle de données
  onUpdate: (data: any) => void;
  availableStatuses?: string[];
  bdd_colonne?: string[];
}

interface Condition {
  csv_column: string;  // Nom de colonne CSV ciblée
  operator: string;    // (=, ≠, contient, etc.)
  values: string[];    // Valeurs (ex: ["val1", "val2"])
}

const AnotherFormComponent: React.FC<AnotherFormComponentProps> = ({
  model,
  onUpdate
}) => {
  // 1) Récupérer les champs depuis model.data
  const [statuses, setStatuses] = React.useState<string[]>(model.data?.statuses || []);
  const [column_mapping, setColumnMapping] = React.useState<any[]>(model.data?.column_mapping || []);
  const [selected_column, setSelectedColumn] = React.useState<string>(model.data?.selected_column || '');
  const [selected_option, setSelectedOption] = React.useState<string>(model.data?.selected_option || '');

  // conditions_by_status : objet dont les clés sont string ("22", "25"...) et la valeur est un tableau de Condition[]
  const [conditions_by_status, setConditionsByStatus] = React.useState<{ [key: string]: Condition[] }>(
    model.data?.conditions_by_status || {}
  );

  // 2) Liste complète des statuts récupérés via une API/OptionService
  //    Format attendu : [ {id: "22", label: "Statut X"}, {id: "25", label: "Statut Y"}, ...]
  const [allStatuses, setAllStatuses] = React.useState<{ id: string; label: string }[]>([]);

  // 3) CSV columns
  const [csvColumns, setCsvColumns] = React.useState<string[]>([]);

  // 4) État local pour ajouter un nouveau statut (via un <select>)
  const [statusToAdd, setStatusToAdd] = React.useState<string>("");

  // ------------------------------
  //   useEffect : Charger les statuts
  // ------------------------------
  React.useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const result = await OptionService.get("interventions_status");
        // Suppose que result est un tableau d'objets {id: string, label: string}
        setAllStatuses(result);
      } catch (error) {
        console.error("Erreur lors du chargement des statuts :", error);
      }
    };
    fetchStatuses();
  }, []);

  // ------------------------------
  //   Parser CSV (PapaParse)
  // ------------------------------

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      if (e.target?.result) {
        // e.target.result est une "binary string"
        const binaryString = e.target.result as string;

        // Détecter l'encodage via jschardet
        const detection = jschardet.detect(binaryString);
        let encoding = detection.encoding ? detection.encoding.toLowerCase() : 'utf-8';

        // Petit ajustement si besoin
        if (encoding === 'win1252') {
          encoding = 'windows-1252';
        }

        console.log('Encodage détecté :', encoding, '| Confiance :', detection.confidence);

        // Convertir la "binary string" en texte avec l'encodage détecté
        const textContent = decodeBinaryString(binaryString, encoding);

        // Enlever d'éventuels BOM
        const cleanText = textContent.replace(/\uFEFF/g, '');

        // Parser avec Papa Parse
        Papa.parse(cleanText, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const data = results.data as Record<string, unknown>[];
            if (data && data.length > 0) {
              setCsvColumns(Object.keys(data[0]));
            }
          },
          error: (error) => {
            console.error('Erreur parsing CSV :', error);
          },
        });
      }
    };

    // Lire le fichier en "binary string"
    fileReader.readAsBinaryString(file);
  };

  // Convertir une "binary string" en texte via TextDecoder
  const decodeBinaryString = (binaryString: string, encoding: string) => {
    // On reconstruit un tableau d'octets (Uint8Array) à partir de la binary string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i) & 0xff;
    }

    // On utilise TextDecoder pour convertir ces octets en "vrai" texte
    const decoder = new TextDecoder(encoding);
    return decoder.decode(bytes);
  };


  // ------------------------------
  //  Synchroniser dans model.data
  // ------------------------------
  const syncToModel = (
    newStatuses = statuses,
    newConditions = conditions_by_status,
    optionOverride?: string,
    columnOverride?: string,
    mappingOverride?: any[]
  ) => {
    onUpdate({
      ...model.data,
      statuses: newStatuses,
      column_mapping: mappingOverride !== undefined ? mappingOverride : column_mapping,
      selected_column: columnOverride !== undefined ? columnOverride : selected_column,
      selected_option: optionOverride !== undefined ? optionOverride : selected_option,
      conditions_by_status: newConditions,
    });
  };

  // ------------------------------
  //   Sélection principale (radio)
  // ------------------------------
  const handleOptionChange = (val: string) => {
    setSelectedOption(val);
    syncToModel(statuses, conditions_by_status, val, undefined);
  };

  const handleColumnChange = (val: string) => {
    setSelectedColumn(val);
    syncToModel(statuses, conditions_by_status, undefined, val);
  };

  // ------------------------------
  //   Ajouter / Retirer / Déplacer un statut
  // ------------------------------
  const handleAddStatus = () => {
    if (statusToAdd && !statuses.includes(statusToAdd)) {
      const newStatuses = [...statuses, statusToAdd];

      const newCond = { ...conditions_by_status };
      if (!newCond[statusToAdd]) {
        newCond[statusToAdd] = [];
      }

      setStatuses(newStatuses);
      setConditionsByStatus(newCond);
      syncToModel(newStatuses, newCond);
    }
    setStatusToAdd("");
  };

  const handleRemoveStatus = (index: number) => {
    const removed = statuses[index];
    const newStatuses = [...statuses];
    newStatuses.splice(index, 1);

    const newCond = { ...conditions_by_status };
    delete newCond[removed];

    setStatuses(newStatuses);
    setConditionsByStatus(newCond);
    syncToModel(newStatuses, newCond);
  };

  const moveStatus = (index: number, direction: "up" | "down") => {
    const newStatuses = [...statuses];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex < 0 || targetIndex >= newStatuses.length) return;

    // Échange
    [newStatuses[index], newStatuses[targetIndex]] = [newStatuses[targetIndex], newStatuses[index]];
    setStatuses(newStatuses);
    syncToModel(newStatuses, conditions_by_status);
  };

  // ------------------------------
  //   Gérer les conditions
  // ------------------------------
  const handleAddCondition = (statusId: string) => {
    const conds = conditions_by_status[statusId] || [];
    const newConds = [...conds, { csv_column: "", operator: "", values: [] }];

    const newObj = { ...conditions_by_status, [statusId]: newConds };
    setConditionsByStatus(newObj);
    syncToModel(statuses, newObj);
  };

  const handleChangeCondition = (
    statusId: string,
    condIndex: number,
    field: keyof Condition,
    value: any
  ) => {
    const conds = conditions_by_status[statusId] || [];
    const newConds = [...conds];
    newConds[condIndex] = { ...newConds[condIndex], [field]: value };

    const newObj = { ...conditions_by_status, [statusId]: newConds };
    setConditionsByStatus(newObj);
    syncToModel(statuses, newObj);
  };

  const handleRemoveCondition = (statusId: string, condIndex: number) => {
    const conds = conditions_by_status[statusId] || [];
    const newConds = [...conds];
    newConds.splice(condIndex, 1);

    const newObj = { ...conditions_by_status, [statusId]: newConds };
    setConditionsByStatus(newObj);
    syncToModel(statuses, newObj);
  };

  // ------------------------------
  //   MultiField => Pour column_mapping
  // ------------------------------
  // On va stocker chaque champ additionnel dans column_mapping
  // en tant qu'objet : { key: string, columns: string[] }
  // Exemple : { key: "instruction", columns: ["colCSV1", "colCSV2"] }
  const allOptions = csvColumns.map((col) => ({ label: col, value: col }));

  // Fonction utilitaire pour créer un composant multiField
  const multiField = (dbKey: string, label: string) => {
    // On récupère la config existante de column_mapping pour ce dbKey
    // Cherche un objet { key: dbKey } dans column_mapping
    const existing = column_mapping.find((item) => item.key === dbKey);
    const selectedVals = existing
      ? existing.columns.map((val: string) => ({ label: val, value: val }))
      : [];

    // Callback quand l'utilisateur modifie le Select
    const onChangeSelect = (options: any) => {
      let newVals: string[] = [];
      if (Array.isArray(options)) {
        newVals = options.map((o) => o.value);
      }
      // Mettre à jour column_mapping
      setColumnMapping((prev) => {
        const newArray = [...prev];
        const index = newArray.findIndex((item) => item.key === dbKey);
        if (index >= 0) {
          newArray[index] = { ...newArray[index], columns: newVals };
        } else {
          newArray.push({ key: dbKey, columns: newVals });
        }
        // Synchroniser dans model
        syncToModel(statuses, conditions_by_status, undefined, undefined, newArray);
        return newArray;
      });
    };

    return (
      <div className="mb-3">
        <label className="text-s text-secondary">{label}</label>
        <Select
          isMulti
          options={allOptions}
          value={selectedVals}
          onChange={onChangeSelect}
          classNamePrefix="select"
        />
      </div>
    );
  };

  // ------------------------------
  //   Rendu JSX
  // ------------------------------
  return (
    <div>
      {/* 1) Import du fichier CSV */}
      <label htmlFor="csvFileAnother">Charger un fichier CSV :</label>
      <input
        type="file"
        id="csvFileAnother"
        accept=".csv"
        onChange={handleFileUpload}
      />

      {/* 2) Sélection principale + colonne */}
      {csvColumns.length > 0 && (
        <div className="mt-4 d-flex align-items-start">
          <div className="me-3" style={{ flex: 1 }}>
            <h3>Sélection de la référence utilisé pour trouver le contact</h3>
            <div className="mb-3">
              <label>
                <input
                  type="radio"
                  name="mainOption"
                  value="Référence client"
                  checked={selected_option === 'Référence client'}
                  onChange={(e) => handleOptionChange(e.target.value)}
                />
                &nbsp;Référence client
              </label>
            </div>
            <div className="mb-3">
              <label>
                <input
                  type="radio"
                  name="mainOption"
                  value="Contrat"
                  checked={selected_option === 'Contrat'}
                  onChange={(e) => handleOptionChange(e.target.value)}
                />
                &nbsp;Contrat
              </label>
            </div>
            <div className="mb-3">
              <label>
                <input
                  type="radio"
                  name="mainOption"
                  value="Compteur"
                  checked={selected_option === 'Compteur'}
                  onChange={(e) => handleOptionChange(e.target.value)}
                />
                &nbsp;Compteur
              </label>
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <h4>Colonne CSV associée</h4>
            <select
              className="form-select"
              value={selected_column}
              onChange={(e) => handleColumnChange(e.target.value)}
            >
              <option value="">-- Sélectionner une colonne CSV --</option>
              {csvColumns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* 3) Liste / Ordre des statuts */}
      <h3 className="mt-4">Sélection des status disponible</h3>
      <div className="d-flex align-items-center mb-3">
        <select
          className="form-select me-2"
          value={statusToAdd}
          onChange={(e) => setStatusToAdd(e.target.value)}
        >
          <option value="">-- Choisir un statut à ajouter --</option>
          {allStatuses
            .filter((st) => !statuses.includes(st.id))
            .map((st) => (
              <option key={st.id} value={st.id}>
                {st.label}
              </option>
            ))}
        </select>
        <button className="btn btn-primary" onClick={handleAddStatus}>
          + Ajouter
        </button>
      </div>

      {statuses.length > 0 && (
        <div>

          {statuses.map((statusId, index) => {
            const statusObj = allStatuses.find((s) => s.id == statusId);
            return (
              <div
                key={statusId}
                className="mb-3 p-2 border"
                style={{ backgroundColor: '#f8f9fa' }}
              >
                <div className="d-flex align-items-center">
                  <strong className="flex-grow-1">
                    {statusObj?.label ?? `Statut ${statusId}`}
                  </strong>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={() => moveStatus(index, 'up')}
                  >
                    ↑
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={() => moveStatus(index, 'down')}
                  >
                    ↓
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleRemoveStatus(index)}
                  >
                    Supprimer
                  </button>
                </div>

                {/* CONDITIONS POUR CE STATUT */}
                <div className="mt-3 ms-2">
                  <h5>Conditions</h5>
                  {(conditions_by_status[statusId] || []).map((condition, cIndex) => (
                    <div key={cIndex} className="d-flex mb-2">
                      {/* Choix de la colonne CSV */}
                      <select
                        className="form-select me-2"
                        style={{ maxWidth: '180px' }}
                        value={condition.csv_column}
                        onChange={(e) =>
                          handleChangeCondition(statusId, cIndex, 'csv_column', e.target.value)
                        }
                      >
                        <option value="">-- Col. CSV --</option>
                        {csvColumns.map((col) => (
                          <option key={col} value={col}>
                            {col}
                          </option>
                        ))}
                      </select>

                      {/* Opérateur */}
                      <select
                        className="form-select me-2"
                        style={{ maxWidth: '120px' }}
                        value={condition.operator}
                        onChange={(e) =>
                          handleChangeCondition(statusId, cIndex, 'operator', e.target.value)
                        }
                      >
                        <option value="">-- Opérateur --</option>
                        <option value="=">=</option>
                        <option value="!=">≠</option>
                        <option value="contient">contient</option>
                        <option value="ne contient pas">ne contient pas</option>
                        <option value="commentce par">commence par</option>
                        <option value="ne commence pas par">ne commence pas par</option>
                        <option value="commentce par">finit par</option>
                        <option value="ne commence pas par">ne finit pas par</option>
                        <option value="est vide">est vide</option>
                        <option value="n'est pas vide">n'est pas vide</option>
                      </select>

                      {/* Valeurs (séparées par ";") */}
                      <input
                        type="text"
                        className="form-control me-2"
                        style={{ maxWidth: '200px' }}
                        value={condition.values.join(';')}
                        onChange={(e) => {
                          const splitted = e.target.value.split(';').map((v) => v.trim());
                          handleChangeCondition(statusId, cIndex, 'values', splitted);
                        }}
                        placeholder="ex: Valeur1;Valeur2"
                      />

                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => handleRemoveCondition(statusId, cIndex)}
                      >
                        X
                      </button>
                    </div>
                  ))}

                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => handleAddCondition(statusId)}
                  >
                    + Condition
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* 4) Les MultiFields à la fin (column_mapping) */}
      {csvColumns.length > 0 && (
        <div className="mt-4">
          <h3>Sélection du remplissage de l'intervention</h3>
          {multiField('instruction', 'Instruction')}
          {multiField('comment', 'Commentaire')}
          {multiField('ref', 'Référence')}
          {multiField('status', 'Statut')}
        </div>
      )}
    </div>
  );
};


export default formModel<ISectorFormProps>(CustomImportForm, { modelClass: CustomImport });
