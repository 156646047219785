import CallsPage from './pages/CallsPage';
import CRMImportsPage from './pages/CRMImportsListPage';
import CrmInterventionSlotPage from './pages/CrmInterventionSlotPage';
import CRMPage from './pages/CRMPage';
import CRMSettingPage from './pages/CRMSettingPage';
import CRMUsersPage from './pages/CRMUsersPage';
import CustomerPage from './pages/CustomerPage';
import CustomersCRMPage from './pages/CustomersCRMPage';
import GodinLibCRMPage from './pages/GodinLibCRMPage';


const getRoutes = () => {
  const routes = [
    {
      icon: "fa-home",
      label: "Dashboard",
      url: "/crm",
      page: CRMPage,
      exact: true,
    },{
      icon: "fa-chart-tree-map",
      label: "Secteurs",
      url: "/crm/sectors",
      page: CustomersCRMPage,
      exact: false,
    },
    {
      icon: "fa-regular fa-clock",
      label: "Godin Lib",
      url: "/crm/godinlib",
      page: GodinLibCRMPage,
      exact: false,
    },
    // {
    //   icon: "fa-address-book",
    //   label: "Contacts",
    //   url: "/crm/contacts",
    //   page: ContactsPage,
    //   exact: true,
    // },
    {
      icon: "fa-phone",
      label: "Appels",
      url: "/crm/calls",
      page: CallsPage,
      exact: true,
    },{
      icon: "fa-users",
      label: "Equipe",
      url: "/crm/users",
      page: CRMUsersPage,
    },{
    //   icon: "fa-map",
    //   label: "Carte",
    //   url: "/crm/map",
    //   page: MapPage,
    //   exact: true,
    // },{
      icon: "fa-calendar-alt",
      label: "Calendrier",
      url: "/crm/calendar",
      page: CrmInterventionSlotPage,
      exact: true,
    },{
      icon: "fa-handshake",
      label: "Client",
      url: "/crm/customers",
      page: CustomerPage,
      exact: true,
    }, {
      icon: "fa-cogs",
      label: "Paramètres",
      url: "/crm/settings",
      page: CRMSettingPage,
      exact: true,
    },
    {
      icon: "fa-cogs",
      label: "Import",
      url: "/crm/import",
      page: CRMImportsPage,
      exact: true,
      roles: ["user_role_admin"]
    },
    // {
    //   icon: "fa-circle-phone-flip",
    //   label: "CRM",
    //   url: "/crm",
    //   page: CRMPage,
    //   exact: true,
    //   roles: ["user_role_admin", "user_role_secretaire", "user_role_manager"]
    // }
    //   icon: "fa-power-off",
    //   label: "Déconnexion",
    //   url: "/logout",
    //   page: LogoutPage,
    //   exact: true
    // }
  ];
  return routes
} 

export default getRoutes;