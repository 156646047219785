import { SidebarRoute } from '@components/layout/SidebarRouter';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import CustomEmail from '@models/CustomEmail';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface ICustomEmailListProps {
}

export default class CustomEmailList extends React.Component<ICustomEmailListProps> {

  onSubmit = (manager) => {
    DOMService.closeSidebar();
    manager.loadModels();
  }

  public render() {
    return (
      <RecordManagerV2<CustomEmail> options={{modelClass: CustomEmail, loadOnReady: true, paginate: false}}>
        {(manager, props) => <>
          <div className="row-between mb-3">
            <h4 className='mb-0'>Emails/SMS personnalisés</h4>
            <div className="row-end">
              <button onClick={() => DOMService.openSidebar(SidebarRoute.CustomEmailForm, {onSubmit: () => this.onSubmit(manager), type: "sms"}) } className="btn btn-primary me-3">Nouveau modèle SMS</button>
              <button onClick={() => DOMService.openSidebar(SidebarRoute.CustomEmailForm, {onSubmit: () => this.onSubmit(manager), type: "email"}) } className="btn btn-primary">Nouveau modèle Email</button>
            </div>
          </div>
          <div className="">
              {props.models?.map((m) => {
              return <div className='mb-3 border rounded-2 py-3 px-4'>
                <div className="row-flex">
                  <i className='fas fa-envelope fa-2x text-secondary me-2'></i>
                  <div className="me-4 text-s">
                    {m.type === "sms" ? <span className='text-info'>SMS</span> : <span className='text-danger'>Email</span>}
                  </div>
                  <div>
                    <div>
                      {m.title}
                    </div>
                    <div className='text-secondary'>
                      {m.object}
                    </div>
                  </div>
                  <div className="ms-auto row-end">
                    <div onClick={async () => {
                      await EntityManager.delete(m);
                      await manager.loadModels();

                    }} className="bg-light height-5 width-5 rounded-2 row-center pointer me-4">
                      <i className='fas fa-trash text-secondary'></i>
                    </div>
                    <div onClick={() => DOMService.openSidebar(SidebarRoute.CustomEmailForm, {id: m.id, onSubmit: () => DOMService.closeSidebar()})} className="bg-light height-5 width-5 rounded-2 row-center pointer">
                      <i className='fas fa-pencil text-secondary'></i>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </>}
      </RecordManagerV2>
    );
  }
}
