import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Sector from "@models/Sector";
import DOMService from "@services/DOMService";
import ModelGrid from "./ModelGrid";
import CustomImport from "@models/CustomImport";

export default class CustomImportGrid extends ModelGrid {

    public formRoute = SidebarRoute.CustomImportForm
    static gridName = "customercrm"

    title() {
        return "Import customisé"
    }

    gridConfig = {
        headers: ["Client", "Secteur", "Type d'import"],
        body: [
            (customImport: CustomImport) => <b>{customImport.sector && customImport.sector.customer && customImport.sector.customer.name}</b>,
            (customImport: CustomImport) => <b>{customImport.sector && customImport.sector.name}</b>,
            (customImport: CustomImport) => <div>{customImport.getTypeName()}</div>,
            //   (sector: Sector) => sector.phone,
            //   (sector: Sector) => <div>
            //   <div>{sector.address}</div>
            //   <div>{sector.cpAndCity()}</div>
            // </div>,

        ]
    }

    public rowActions = (model: CustomImport) => <>
        
    </>

   

    onRowClick = (model) => {
        sessionBloc.push("/crm/import/" + model.id)
    };
}