import AdminModelsList from '@components/common/AdminModelsList';
import MainLayout from '@components/layout/MainLayout';
import * as React from 'react';
import CustomImportGrid from '@components/grid/CustomImportGrid';
import CustomImport from '@models/CustomImport';
export interface ICustomersCRMPageProps {
}

export default class CRMImportsPage extends React.Component<ICustomersCRMPageProps> {
  public render() {
    return (
      <MainLayout title="Import customisé">
        <div className="app-content">
            <AdminModelsList
                gridClass={CustomImportGrid}
                options={{ loadOnReady: true, paginate: true, modelClass: CustomImport}}
            />
        </div>
      </MainLayout>
    );
  }
}
