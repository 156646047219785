import PasswordUpdatePage from '@components/common/PasswordUpdatePage'
import StatusDialog from '@components/common/StatusDialog'
import ContactsGridSidebar from '@components/crm/ContactsGridSidebar'
import ContactShowSidebar from '@components/crm/ContactShowSidebar'
import ExportSuiviPanel from '@components/crm/ExportSuiviPanel'
import FullContactShowSidebar from '@components/crm/FullContactShowSidebar'
import ImportDialog from '@components/crm/ImportDialog'
import InterventionSlotForm from '@components/crm/InterventionSlotForm'
import MapPlaceDetail from '@components/crm/MapPlaceDetail'
import SentDetail from '@components/crm/SentDetail'
import WorkerSlot from '@components/crm/WorkerSlot'
import AlertForm from '@components/form/AlertForm'
import ContactForm from '@components/form/ContactForm'
import CustomEmailForm from '@components/form/CustomEmailForm'
import CustomerForm from '@components/form/CustomerForm'
import ExchangeForm from '@components/form/ExchangeForm'
import InterlocutorForm from '@components/form/InterlocutorForm'
import InterventionForm from '@components/form/InterventionForm'
import PrestationForm from '@components/form/PrestationForm'
import ReportForm from '@components/form/ReportForm'
import SectorForm from '@components/form/SectorForm'
import SectorDelete from '@components/form/SectorDelete'
import SlotForm from '@components/form/SlotForm'
import UserForm from '@components/form/UserForm'
import YardForm from '@components/form/YardForm'
import YardShowSidebar from '@components/yard/YardShowSidebar'
import UserToQuartier from "@components/crm/UserToQuartier"
import SendPage from 'src/pages/SendPage'
import UserSendPage from 'src/pages/UserSendPage'
import YardSharePage from 'src/pages/YardSharePage'
import DOMService from '../../services/DOMService'
import CustomRouter from "./CustomRouter"
import SendToMultiplePage from 'src/pages/SendToMultiplePage'
import SetUserGodinLib from '@components/crm/SetUserGodinLib'
import SectorReservationInfoForm from '@components/form/SectorReservationInfoForm'
import VehiculeForm from "@components/form/VehiculeForm";
import VehiculeDetails from "../../pages/VehiculeDetails";
import TransferWorkerSlot from '@components/crm/TransferWorkerSlot'
import CustomImportForm from '@components/form/CustomImportForm'


export enum SidebarRoute {
  YardForm, CustomerForm, ReportForm, UserForm, PrestationForm, YardShow, SlotForm, PasswordUpdatePage, YardShare, ExchangeForm, CustomEmailForm, SendPage, AlertForm, UserSendPage, ContactShow, ContactForm, InterventionSlotForm, SentDetail, ExportSuiviPanel, ContactsGridSidebar, MapPlaceDetail, WorkerContactShow, ImportDialog, StatusDialog, SectorForm, SectorDelete, InterlocutorForm, FullContactShow, InterventionForm, UserToQuartier,
  SendToMultiplePage,
  SetUserGodinLib,
  SectorReservationInfoForm,
  VehiculeForm,
  VehiculeDetails,
  TransferWorkerSlot,
  CustomImportForm
}

class SidebarRouter extends CustomRouter<SidebarRoute> {

  constructor() {
    super({
      [SidebarRoute.YardForm]:                 YardForm,
      [SidebarRoute.ReportForm]:               ReportForm,
      [SidebarRoute.CustomerForm]:             CustomerForm,
      [SidebarRoute.UserForm]:                 UserForm,
      [SidebarRoute.PrestationForm]:           PrestationForm,
      [SidebarRoute.YardShow]:                 YardShowSidebar,
      [SidebarRoute.SlotForm]:                 SlotForm,
      [SidebarRoute.AlertForm]:                AlertForm,
      [SidebarRoute.PasswordUpdatePage]:       PasswordUpdatePage,
      [SidebarRoute.YardShare]:                YardSharePage,
      [SidebarRoute.ExchangeForm]:             ExchangeForm,
      [SidebarRoute.CustomEmailForm]:          CustomEmailForm,
      [SidebarRoute.SendPage]:                 SendPage,
      [SidebarRoute.UserSendPage]:             UserSendPage,
      [SidebarRoute.ContactShow]:              ContactShowSidebar,
      [SidebarRoute.FullContactShow]:          FullContactShowSidebar,
      [SidebarRoute.ContactForm]:              ContactForm,
      [SidebarRoute.InterventionSlotForm]:     InterventionSlotForm,
      [SidebarRoute.SentDetail]:               SentDetail,
      [SidebarRoute.ExportSuiviPanel]:         ExportSuiviPanel,
      [SidebarRoute.ContactsGridSidebar]:      ContactsGridSidebar,
      [SidebarRoute.MapPlaceDetail]:           MapPlaceDetail,
      [SidebarRoute.WorkerContactShow]:        WorkerSlot,
      [SidebarRoute.ImportDialog]:             ImportDialog,
      [SidebarRoute.StatusDialog]:             StatusDialog,
      [SidebarRoute.SectorForm]:               SectorForm,
      [SidebarRoute.CustomImportForm]:               CustomImportForm,
      [SidebarRoute.SectorDelete]:               SectorDelete,
      [SidebarRoute.InterlocutorForm]:         InterlocutorForm,
      [SidebarRoute.InterventionForm]:         InterventionForm,
      [SidebarRoute.UserToQuartier]:           UserToQuartier,
      [SidebarRoute.SetUserGodinLib]:           SetUserGodinLib,
      [SidebarRoute.SendToMultiplePage]:           SendToMultiplePage,
      [SidebarRoute.SectorReservationInfoForm]:           SectorReservationInfoForm,
      [SidebarRoute.VehiculeForm]:           VehiculeForm,
      [SidebarRoute.VehiculeDetails]:           VehiculeDetails,
      [SidebarRoute.TransferWorkerSlot]: TransferWorkerSlot
    }) 
  }

  showError(error) {
    DOMService.sidebarLayoutRef.setState({error});
  }
}

export default new SidebarRouter()
