import ContactSlotCalendar from '@components/calendar/ContactSlotCalendar';
import LightTabs from '@components/common/LightTabs';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Contact';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import ContactActions from './ContactActions';
import ContactComments from './ContactComments';
import ContactInfo from './ContactInfo';
import ContactRdvNext from './ContactRdvNext';
import ExchangesList from './ExchangesList';
import InterventionsList from './InterventionsList';
import CustomImport from '@models/CustomImport';

export interface IContactShowProps extends IInjectedShowModelProps<Contact> {
  id
  oneCol?: boolean
}

class CustomImportShow extends React.Component<IContactShowProps> {

  contactCalendar = React.createRef<ContactSlotCalendar>()

  edit() {
    DOMService.openSidebar(SidebarRoute.CustomImportForm, {id: this.props.id, onSubmit: () => {
      this.reload()
    }})
  }

  // componentDidUpdate(prevProps, prevState: Readonly<any>, snapshot?: any): void {
  //   if (prevProps.id !== this.props.id) this.props.load();
  // }

  reload() {
    DOMService.closeSidebar();
    this.props.load();
  }

  public render() {
    const { model, oneCol } = this.props;
    
    return (
      <div>
        <div className="row">
            <div className="col-md-6 col-12 mb-3">
                <div className="row-end">
                    <button onClick={() => this.edit()} className="btn btn-primary ms-3">Modifier</button>
                </div>
          </div>
        </div>
      </div>
    );
  }
}

export default showModel(CustomImportShow, {modelClass: CustomImport}) as any